import React from "react"
import { formatISO } from "date-fns"

interface Props {
    data: {
        headline: string,
        url: string,
        articleBody: string,
        articleSection: string,
        image?: string,
        description?: string,
        created: Date,
        wordCount: number,
        siteUrl: string
    }
}

function MicroData(props: Props) {

    const {
        headline,
        url,
        articleBody,
        articleSection,
        image,
        description,
        created,
        wordCount,
        siteUrl
    } = props.data

    const createdISO = formatISO(new Date(created))

    const data = {
        "@context": "https://schema.org/",
        "@type": "BlogPosting",
        "headline": headline,
        "articleBody": articleBody,
        "articleSection": articleSection,
        "description": description,
        "url": url,
        "isFamilyFriendly": "true",
        "inLanguage": "sv-SE",
        "dateCreated": createdISO,
        "datePublished": createdISO,
        "dateModified": createdISO,
        "wordCount": wordCount,
        "image": image,
        "mainEntityOfPage": url,
        "author": {
            "@type": "Person",
            "name": "Nicklas Jarnesjö",
            "url": "https://jarnesjo.net"
        },
        "creator": {
            "@type": "Person",
            "name": "Nicklas Jarnesjö",
            "url": "https://jarnesjo.net"
        },
        "publisher": {
            "@type": "Organization",
            "name": "Nicklas Jarnesjö",
            "url": "https://jarnesjo.net",
            "logo": {
                "@type": "ImageObject",
                "url": `${siteUrl}/images/nicklas-jarnesjo.jpg`,
                "width": "400",
                "height": "400"
            }
        }
    }

    return (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}>
        </script>
    )
}

export { MicroData }