import React from "react"

interface Props {
    children: string
}

export const h1 = (props: Props) => <h1>{props.children}</h1> // Defined in style.css

export const h2 = (props: Props) => <h2 className="text-2xl mb-2">{props.children}</h2> // Some defined in style.css

export const h3 = (props: Props) => <h3 className="text-xl mb-2">{props.children}</h3> // Some defined in style.css

export const p = (props: Props) => <p>{props.children}</p> // Defined in style.css

export const blockquote = (props: Props) => <blockquote className="italic font-serif text-2xl border-l-4 border-gray-600 pl-8">{props.children}</blockquote>

export const photocred = (props: Props) => <div className="text-sm text-gray-600 italic mt-12">{props.children}</div>

export const mediaContainer = (props: Props) => <div className="my-10 md:-mx-10">{props.children}</div>