import React from "react"
import { graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image";
import rehypeReact from "rehype-react";
import { format, formatDistanceToNow } from "date-fns";
import { sv } from 'date-fns/locale';

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { DraftBadge } from "../components/article/DraftBadgeComponent";
import { MicroData } from "../components/microdata"
import {
    h1,
    h2,
    h3,
    p,
    blockquote,
    photocred,
    mediaContainer
} from "../components/content/postContentElements"

interface Props {
    data: {
        markdownRemark: {
            excerpt: string,
            timeToRead: number,
            rawMarkdownBody: string,
            htmlAst: object,
            wordCount: {
                words: number
            }
            fields: {
                slug: string
            }
            frontmatter: {
                title: string,
                description: string,
                date: Date,
                featuredImage?: {
                    childImageSharp: {
                        fluid: FluidObject
                    }
                }
                showFeaturedImage: boolean,
                photoCred: string
                published: boolean,
                category: string
            }
        },
        site: {
            siteMetadata: {
                title: string
                siteUrl: string,
                defaultImage: string
            }
        }
    },
    pageContext: object,
    location: Location
}

const PostTemplate = ({ data, location }: Props) => {
    const post = data.markdownRemark
    const { title: siteTitle, defaultImage, siteUrl } = data.site.siteMetadata
    const { timeToRead } = post

    const {
        title,
        description,
        date,
        featuredImage,
        showFeaturedImage = false,
        photoCred,
        published,
        category
    } = post.frontmatter

    const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
            h1,
            h2,
            h3,
            p,
            blockquote,
            photocred,
            "media-container": mediaContainer,
        }
    }).Compiler

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={title}
                description={description || post.excerpt}
                image={featuredImage ? featuredImage.childImageSharp.fluid.src : null}
            />

            <MicroData data={{
                headline: title,
                description: description || post.excerpt,
                created: date,
                url: data.site.siteMetadata.siteUrl + post.fields.slug,
                articleBody: post.rawMarkdownBody,
                articleSection: category,
                wordCount: post.wordCount.words,
                image: featuredImage ? `${siteUrl}${featuredImage.childImageSharp.fluid.src}` : `${siteUrl}${defaultImage}`,
                siteUrl
            }} />

            <article>
                {(featuredImage && showFeaturedImage) &&
                    <div className="relative">
                        <Img fluid={featuredImage.childImageSharp.fluid} className="mt-10 mb-6" />
                        {photoCred &&
                            <div className="absolute bottom-0 right-0 py-2 px-4 text-xs text-gray-600">{photoCred}</div>
                        }
                    </div>
                }

                <div className="max-w-2xl mx-auto">
                    <header>
                        <h1 className="text-4xl leading-tight m-0">
                            {title}
                            <DraftBadge published={published} />
                        </h1>
                    </header>

                    <div className="text-gray-700 py-4 dark:text-dark-secondary">
                        <span title={format(new Date(date), 'iiii d MMMM yyyy', { locale: sv })}>
                            {date ? formatDistanceToNow(new Date(date), { locale: sv, addSuffix: true }) : ''}
                        </span>
                        <span className="mx-2">-</span><span>{timeToRead} min lästid</span>
                    </div>

                    <section>
                        {renderAst(post.htmlAst)}
                    </section>
                </div>
            </article>
        </Layout >
    )
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        defaultImage
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) { 
      id
      excerpt(pruneLength: 160)
      timeToRead
      rawMarkdownBody
      htmlAst
      wordCount {
          words
      }
      fields {
          slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY HH:ss")
        description
        author
        tags
        category
        showFeaturedImage
        photoCred
        published
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1024, maxHeight: 420) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
